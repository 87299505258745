<script>
export default {
    props: {
        box: String,
        title: String,
        height: Number,
        notStretched: {
            type: Boolean,
            default: false,
        },
        legacy: {
            type: Boolean,
            required: false,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        primaryTitle: {
            type: Boolean,
            default: false,
        },
        redTitle: {
            type: Boolean,
            default: false,
        },
        blueTitle: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        calculatedHeight() {
            if (this.height) {
                return `${this.height}px`;
            }

            return this.notStretched ? 'inherit' : '100%';
        },
        overflow() {
            if (this.height) {
                return 'auto';
            }

            return 'visible';
        },
        classes() {
            const classes = [];
            classes.push(this.box);

            if (this.transparent) {
                classes.push('bg-gray-200/80');
            }
            else {
                classes.push('bg-stone-300');
            }

            return classes.join(' ');
        },
        style() {
            const style = {
                height: this.calculatedHeight,
                overflowY: this.overflow,
            };

            if (this.legacy) {
                style.padding = '8px';
            }

            return style;
        },
        titleClasses() {
            const classes = [];
            if (this.primaryTitle) {
                classes.push('bg-green-700');
                classes.push('text-white');
            }

            if (this.redTitle) {
                classes.push('bg-red-700');
                classes.push('text-white');
            }

            if (this.blueTitle) {
                classes.push('bg-blue-700');
                classes.push('text-white');
            }

            return classes.join(' ');
        },
    },
};
</script>

<template>
    <div
        class="relative overflow-auto rounded-sm shadow-md"
        :class="classes"
        :style="style"
    >
        <div
            class="flex flex-wrap items-center text-xl font-weight-medium"
            :class="titleClasses"
        >
            <slot name="title">
                <div class="p-4" v-if="title">{{ title }}</div>
            </slot>
        </div>
        <slot />
    </div>
</template>
