<script>
export default {
    props: {
        section: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            images: [
                '40606057_l.jpg',
                '40606092_l.jpg',
                '44398893_l.jpg',
                '44476483_l.jpg',
            ],
        };
    },
    computed: {
        backgroundImage() {
            return this.section === 'landingpage' ? `url(/images/background/${this.images[Math.floor(Math.random() * this.images.length)]})` : '';
        },
    },
};
</script>

<template>
    <div class="background" :style="{ backgroundImage }" />
</template>

<style scoped>
.background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-zinc-800);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center top;
}
</style>
