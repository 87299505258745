<script setup lang="ts">
import { computed } from 'vue';

interface Props {
    variant?: 'primary' | 'secondary' | 'danger' | 'info';
    submit?: boolean;
    disabled?: boolean;
    block?: boolean;
    href?: string | null;
    target?: string | null;
    id?: string | null;
}
const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    submit: false,
    disabled: false,
    block: false,
    href: null,
    target: null,
    id: null,
});

const classes = computed(() => {
    const classes = ['font-medium', 'text-sm', 'text-center', 'tracking-wider', 'py-2', 'px-4', 'rounded-sm', 'uppercase', 'border-2', 'border-solid', 'cursor-pointer'];

    classes.push(props.block ? 'block' : 'inline-block');

    switch (props.variant) {
        case 'primary':
            classes.push('bg-green-700');
            classes.push('border-green-700');
            classes.push('text-white');
            classes.push('hover:bg-green-600');
            classes.push('hover:border-green-600');
            break;

        case 'secondary':
            classes.push('border-gray-700');
            classes.push('text-gray-700');
            classes.push('hover:border-gray-600');
            break;

        case 'danger':
            classes.push('bg-red-600');
            classes.push('border-red-600');
            classes.push('text-white');
            classes.push('hover:bg-red-500');
            classes.push('hover:border-red-500');
            break;

        case 'info':
            classes.push('bg-blue-700');
            classes.push('border-blue-700');
            classes.push('text-white');
            classes.push('hover:bg-blue-600');
            classes.push('hover:border-blue-600');
            break;
    }

    if (props.disabled) {
        classes.push('opacity-30');
        classes.push('pointer-events-none');
    }

    return classes.join(' ');
});
</script>

<template>
    <button
        v-if="!href"
        :id="id"
        :class="classes"
        :type="submit ? 'submit' : 'button'"
        :disabled="disabled"
    >
        <slot />
    </button>
    <a
        v-else
        :id="id"
        :class="classes"
        :href="href"
        :target="target"
        @click="$emit('click', $event)"
    >
        <slot />
    </a>
</template>
