<script setup lang="ts">
import type { TabsContentProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import { TabsContent } from 'radix-vue';
import { cn } from '../utils';

type Props = Pick<TabsContentProps, 'value'> & {
    class?: HTMLAttributes['class'];
};
const props = defineProps<Props>();
</script>

<template>
    <tabs-content
        :value="value"
        :class="cn('p-4', props.class)"
    >
        <slot />
    </tabs-content>
</template>
