<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { computed, ref, watch } from 'vue';

interface Props {
    label?: string;
    modelValue?: string | number;
    name?: string;
    autoGrow?: boolean;
    class?: HTMLAttributes['class'];
}
interface Emits {
    'update:modelValue': [value: string | number];
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: '',
    password: false,
    number: false,
    autoGrow: false,
});
const emits = defineEmits<Emits>();

const inputValue = ref(props.modelValue);
watch(() => props.modelValue, (value: string | number) => inputValue.value = value);
watch(() => inputValue.value, (value: string | number) => emits('update:modelValue', value));

const isFocused = ref(false);

const isLabelMovedUp = computed(() => isFocused.value || !!inputValue.value);

const classes = computed(() => {
    const classes = [];

    if (isFocused.value) {
        classes.push('border-gray-900');
        classes.push('border-2');
    }
    else {
        classes.push('border-gray-700');
        classes.push('border');
    }

    if (props.autoGrow) {
        classes.push('overflow-hidden');
        classes.push('h-10');
    }
    else {
        classes.push('h-32');
    }

    return classes;
});

const labelClasses = computed(() => {
    const classes = [];
    if (isLabelMovedUp.value) {
        classes.push('-top-2');
        classes.push('text-xs');
        classes.push('text-gray-900');
    }
    else {
        classes.push('top-2');
        classes.push('text-base');
        classes.push('text-gray-600');
    }

    return classes;
});

function autoGrow(event: Event) {
    if (!props.autoGrow) {
        return;
    }

    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = `${textarea.scrollHeight - 6}px`;
}
</script>

<template>
    <div class="relative" :class="props.class">
        <textarea
            autocomplete="off"
            class="relative appearance-none bg-transparent min-h-10 px-4 py-2 rounded-sm w-full focus:outline-hidden hover:border-gray-700"
            :class="classes"
            :name="name"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @input="autoGrow"
            v-model="inputValue"
        />
        <label
            v-if="label"
            class="absolute left-2 transition-all pointer-events-none px-2 whitespace-nowrap bg-stone-300"
            :class="labelClasses"
        >
            {{ label }}
        </label>
    </div>
</template>
