<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { computed, ref, watch } from 'vue';
import { cn } from '../utils';

interface Props {
    label?: string;
    modelValue?: string | number;
    name?: string;
    class?: HTMLAttributes['class'];
    password?: boolean;
    number?: boolean;
    labelColor?: string;
}
interface Emits {
    'update:modelValue': [value: string | number];
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: '',
    password: false,
    number: false,
});
const emits = defineEmits<Emits>();

const inputValue = ref(props.modelValue);
watch(() => props.modelValue, (value: string | number) => inputValue.value = value);
watch(() => inputValue.value, (value: string | number) => emits('update:modelValue', value));

const isFocused = ref(false);

const isLabelMovedUp = computed(() => isFocused.value || !!inputValue.value);

const classes = computed(() => {
    const classes = [];
    if (isLabelMovedUp.value) {
        classes.push('-top-2');
        classes.push('text-xs');
        classes.push('text-gray-900');
    }
    else {
        classes.push('top-2');
        classes.push('text-base');
        classes.push('text-gray-600');
    }
    if (!props.labelColor) {
        classes.push('bg-stone-300');
    }

    return classes;
});

const type = computed(() => {
    if (props.password) {
        return 'password';
    }

    if (props.number) {
        return 'number';
    }

    return 'text';
});
</script>

<template>
    <div class="relative" :class="props.class">
        <input
            :type="type"
            autocomplete="off"
            :class="cn(
                'relative appearance-none bg-transparent h-10 py-2 rounded-sm w-full focus:outline-hidden border hover:border-gray-700',
                {
                    'border-gray-900': isFocused,
                    'border-2': isFocused,
                    'px-[15px]': isFocused,
                    'border-gray-700': !isFocused,
                    'border': !isFocused,
                    'px-[16px]': !isFocused,
                },
            )"
            :name="name"
            @focus="isFocused = true"
            @blur="isFocused = false"
            v-model="inputValue"
        />
        <label
            v-if="label"
            class="absolute left-2 transition-all pointer-events-none px-2 whitespace-nowrap"
            :class="classes"
            :style="`background-color: ${props.labelColor}`"
        >
            {{ label }}
        </label>
    </div>
</template>
