<script setup lang="ts">
import type { TabsRootProps } from 'radix-vue';
import { TabsRoot } from 'radix-vue';
import { ref } from 'vue';

type Props = Pick<TabsRootProps, 'defaultValue'> & {
    id?: string;
};
const props = defineProps<Props>();

const modelValue = ref(props.defaultValue);

if (props.id) {
    const value = JSON.parse(window.localStorage.getItem(`tabs_${props.id}`));
    if (value) {
        modelValue.value = value.value;
    }
}
function update(updatedModelValue: string) {
    modelValue.value = updatedModelValue;

    if (props.id) {
        const value = { value: modelValue.value };
        window.localStorage.setItem(`tabs_${props.id}`, JSON.stringify(value));
    }
}
</script>

<template>
    <tabs-root
        :default-value="defaultValue"
        @update:model-value="update"
        :model-value="modelValue"
    >
        <slot />
    </tabs-root>
</template>
